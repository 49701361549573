.left {
    float: left;
}

.right {
    float: right;
}

.hidden {
    display: none;
}
