@import "../../rainbow/scss/colors";

.ui-datepicker {
    display: none;
    border: 1px solid $searchbox-autocompete-border;
    border-radius: 2px;
    background-color: $white;
    font-size: 13px;

    select {
        width: 60px;
        font-size: 13px;

        &.ui-datepicker-month {
            width: 120px;
            margin-right: 5px;
        }
    }

    .ui-datepicker-prev {
        display: none;
    }

    .ui-datepicker-next {
        display: none;
    }

    .ui-datepicker-title {
        padding: 10px 4px 4px;
        clear: both;
        font-size: 13px;
    }

    .ui-datepicker-calendar {
        margin-bottom: 4px;

        th {
            padding: 5px 7px;
            text-align: right;
        }

        td {
            text-align: right;
        }

        a {
            display: inline-block;
            padding: 5px 7px;

            &.ui-state-active {
                background-color: $lightblue;
                color: $white;
            }

            &.ui-state-hover {
                background-color: $orange;
                color: $white;
            }
        }
    }
}
