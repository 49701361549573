@import "../../rainbow/scss/colors";

.ui-autocomplete {
    position: absolute;
    z-index: 100;
    margin: 0;
    padding: 0;
    border-width: 0 1px 1px;
    border-color: $black;
    background-color: $white;
    cursor: pointer;

    .ui-menu-item {
        padding: 5px;
        list-style: none;
    }
}
