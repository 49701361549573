@import "../../rainbow/scss/colors";

.swatches {
    display: flex;
    clear: both;

    .swatch {
        display: block;
        flex: 0 1 ($unit * 6);
        height: $unit * 6;
        margin-right: $doubleunit;
        float: left;
        border: $halfunit solid transparent;
        background-color: $white;

        &.selected {
            border-color: $orange;
        }

        @each $name, $color in $background-colors {
            &.#{$name} {
                background-color: $color;
            }
        }

        @media only screen and (max-width: $mobile-max-viewport-width) {
            flex: 0 1 ($unit * 5);
            height: $unit * 5;
        }
    }

    .swatch:first-child {
        border: 1px solid $block-grey;

        &.selected {
            border: $halfunit solid transparent;
            border-color: $orange;
        }
    }
}
