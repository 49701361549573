.register.row,
.verification.row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;

    @media all and (-ms-high-contrast: none) {
        display: block;
    }
}

.authwidgets_registration-container {
    flex: 1 50em;
    max-width: 50em;

    .container-content {
        padding: 8px 12px 16px; // also put in reset-password
    }

    input {
        height: auto;
    }

    input[type="radio"] {
        margin-right: 0;
    }

    .form-group {
        &.account-exists-info {
            margin-bottom: .25em;
        }

        &.name {
            margin-top: .5em;
        }

        &.group-text {
            margin-top: 1em;
        }

        &.generic-message.last-group {
            margin-top: 1em !important;
        }

        &.navigation {
            .secondary-link {
                height: 2.25em;
            }
        }

        &.gender {
            flex-direction: row;
            margin-top: 1em;
        }

        &.terms-conditions {
            margin-top: 1.25em;
        }
    }

    .confirmation-text {
        text-align: center;
    }
}
