@import "../../rainbow/scss/colors";

.linkpage {
    .block-content {
        padding: 0 $block-side-padding;
    }

    .block-content:nth-child(2) { // "Persoonlijke links"
        padding-top: $block-top-padding-non-text;
        padding-bottom: $block-section-bottom-margin-non-text;

        ul { // List of links
            margin: 0;
            padding: 0;

            li { // Single link
                display: flex;
                align-items: center;
                gap: .5rem;
                width: auto;
                margin-bottom: $halfunit * 1.5;
                padding: .25rem $unit;
                border: thin solid $darkblue;
                border-radius: 2px;
                cursor: pointer;

                @media only screen and (max-width: $mobile-max-viewport-width) {
                    line-height: 2.5em;
                }

                .delete,
                .edit {
                    display: inline-flex;
                    align-items: center;
                    height: 100%;
                }

                a { // Same link-style as on homepage
                    color: black;
                    text-decoration: none;
                    vertical-align: middle;

                    &:hover {
                        color: #b22222;
                        text-decoration: underline;
                    }
                }

                .drag {
                    flex: 1 0 5rem;
                    color: $darkblue;
                    font-size: 12px;
                    text-align: right;
                }
            }
        }

        .template {
            display: none;
        }
    }

    .block-content:nth-child(3) { // "Add link" section
        padding-bottom: $block-section-bottom-margin-non-text;

        h3 { // Title "Link toevoegen"
            margin: 0 0 $header-bottom-margin-non-text;
        }

        input {
            width: 70%;
            margin-bottom: $input-bottom-margin;
        }

        .button-secondary.submit { // Button "Toevoegen"
            width: 20%;
            min-width: $unit * 6;
        }

        .cancel {
            display: none;
        }

        @media only screen and (max-width: $mobile-max-viewport-width) {
            input {
                width: 100%;
            }

            .button-secondary.submit {
                width: 100%;
            }
        }
    }

    .block-content:nth-child(4) { // "Links per pagina" section
        padding-bottom: $block-bottom-padding;

        h3 {
            margin: 0 0 $block-bottom-padding;
        }

        select {
            width: $unit * 6;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
