@import "../../rainbow/scss/colors";

.privacypage {
    .block-content {
        // Block "Cookie-instellingen"
        p:nth-of-type(3) { // The three links below the cookie-explanation
            display: flex;
            justify-content: space-between;

            a {
                flex: 0 1 auto;
                margin-right: $unit * 1.5;
                color: $link-text;
            }
        }

        // Install plugin button
        .plugin-button {
            width: 25%;
            margin: $halfunit 0;
            float: left;
            color: $orange;
        }

        // Block "Kinderslot"
        h3 { // Title "Wachtwoord"
            margin: 0 0 $header-bottom-margin;
        }

        form {
            padding: 0;

            input {
                margin-bottom: $unit;
            }
        }

        div label { // Labels for password inputs
            display: block;
            margin-bottom: $halfunit;
        }

        .input-row:nth-child(2) { // Container for "Activeer kinderslot"
            margin: 0 0 $block-section-bottom-margin;

            label {
                display: flex;
                align-items: center;

                input { // Checkbox "Activeer kinderslot"
                    margin-right: $unit;
                }
            }
        }

        #child-lock-current-password-fieldset {
            h3 { // Label for current password
                font-size: 13px;
                font-weight: normal;
            }

            // stylelint-disable-next-line selector-max-id
            #child-lock-current-password { // Input for current password
                margin-bottom: $halfunit;
            }

            p { // Explanation-text
                color: $text--muted;
                font-size: 12px;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

// Install plugin image
.plugin-image {
    margin-bottom: 3rem;
}
