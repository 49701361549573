$mobile-max-viewport-width: 500px;

@import "datepicker";
@import "autocomplete";
@import "./authwidgets";
@import "./authwidgets_authentication";
@import "./authwidgets_registration";
@import "spacing";
@import "utility";
@import "layout";
@import "block";
@import "message-box";
@import "button";
@import "input";
@import "personal-data";
@import "privacy";
@import "links";
@import "location";
@import "display";

.profile.row {
    margin-top: $page-top-margin;
    margin-bottom: $page-bottom-margin;

    .turn-on-tablet {
        margin-bottom: $block-section-bottom-margin;
    }
}

// TODO - remove then Tailwind is used in profile app

.profile-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;

    & > svg {
        width: 100%;
        height: 100%;
    }

    &.arrow-left {
        scale: -1 1;
    }
}
