@import "../../rainbow/scss/colors";

.locationpage {
    h3 { // Titles "Mijn locaties" & "Locatie toevoegen"
        margin: 0 0 $header-bottom-margin-non-text;
    }

    // First section with checkbox
    div.locations {
        .input-row {
            display: flex;
            align-items: center;
            margin: 0 0 $block-section-bottom-margin;
        }

        form {
            padding: 0;
        }

        #allow-location { // checkbox
            height: auto;
            margin-right: $icon-right-margin;
            line-height: auto;
        }
    }

    // List "Mijn locaties"
    ul.locations {
        margin-bottom: $block-section-bottom-margin;
        padding: 0 0 0 6px;

        li:first-child {
            visibility: hidden;
            height: 0;
        }

        .default,
        .empty {
            padding-left: $doubleunit;
        }

        .location-delete {
            margin-right: $icon-right-margin;

            .fa {
                color: $darkblue;
                cursor: pointer;
            }
        }
    }

    // Section "Locatie toevoegen"
    #add-location-form {
        width: 90%;

        @media only screen and (max-width: $mobile-max-viewport-width) {
            width: 100%;
        }

        .add-location { // Input for city or other location
            width: calc(100% - (#{$unit} * 12) - 12px);
            float: right;
        }

        .location-auto { // Button to retrieve location
            width: $unit * 12;
            height: 30px; // same height as input field .add-location
            margin: 0 0 $unit $unit * 1.5;
            padding-top: 8px;
            float: right;
            background-color: $blue;
            font-size: 12px;

            .profile-icon {
                margin-right: .5rem;
            }
        }

        .add-location-title {
            width: 100%;
            margin-bottom: $input-bottom-margin; // Input for location title
        }

        .location-add { // Button to add location
            width: 20%;

            @media only screen and (max-width: $mobile-max-viewport-width) {
                width: 100%;
            }
        }
    }
}
