/* stylelint-disable no-descending-specificity, no-duplicate-selectors, scss/at-extend-no-missing-placeholder */
// disabling these three since rewrite could cause issues with authwidgets
@import "../../rainbow/scss/base/colors";

$color-validation-error: var(--red-500);
$color-validation-success: var(--green-500);
$color-generic-message-border: var(--red-500);
$color-generic-message-background: var(--red-200);
$color-toggle-password-icon: $darkgrey;

.authwidgets_authentication-container,
.authwidgets_registration-container {
    margin: 40px auto 56px;
    background-color: white;
    box-shadow: -2px 5px 18px -7px rgba(0 0 0 / 25%);

    @media only screen and (max-width: 500px) {
        width: 98%;
        margin: 20px auto;
    }

    .container-header {
        height: 32px;
        padding: 4px 8px;
        border-radius: 2px 2px 0 0;
        background-color: $blue;
        color: $white;
        line-height: 22px;

        .text {
            margin: 0;
            font-size: 13px;
        }
    }

    .container-content {
        padding: 8px 12px 16px;
    }

    form {
        .input-group,
        fieldset {
            margin: 4px 0 0;
            padding: 0;
            border: none;

            svg {
                top: .375em;
            }

            .icon-success {
                color: $color-validation-success;
            }

            .icon-error {
                color: $color-validation-error;
            }

            input:not([type="checkbox"], [type="radio"]) {
                height: 2.5em;
                padding-left: 8px;
                border: 1px solid $input-border;
                border-radius: 2px;

                &.validation--success {
                    border: 1px solid $color-validation-success;
                }

                &.validation--error {
                    border: 1px solid $color-validation-error;
                }
            }

            .error-message {
                padding-top: .25em;
                color: $color-validation-error;
            }

            .icon-eye,
            .icon-eye-off {
                color: $color-toggle-password-icon;
            }
        }

        .form-group {
            &.generic-message {
                margin-bottom: .75em;
                padding: 8px;
                border: 1px solid $color-generic-message-border;
                background-color: $color-generic-message-background;

                &.first-group {
                    margin-top: .75em;
                    margin-bottom: .75em;
                }

                &.last-group {
                    margin-top: 1.5em;
                    margin-bottom: 1em;
                }
            }

            &.navigation {
                margin-top: 2em;

                button {
                    width: 60%;
                    height: 32px;
                    padding: 9px;
                    border: none;
                    border-radius: 2px;
                    background-color: $orange;
                    color: $white;
                    font-size: 13px;
                    text-decoration: none;
                }
            }

            &.g-recaptcha {
                height: 0;
                margin: 0;
            }

            &.optin {
                align-items: center;
            }
        }
    }

    .confirmation-container {
        margin-bottom: 0;

        .navigation {
            height: auto;
            margin-top: 1.5em;
        }
    }
}
