.login.row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}

.authwidgets_authentication-container {
    .form-group {
        &.emailaddress {
            margin-top: .5em;
        }
    }

    &.reset-password-container {
        form {
            .form-group {
                &.introduction-text {
                    margin-top: .5em;
                }

                &.emailaddress {
                    margin-top: 1.5em;
                }

                &.navigation {
                    .secondary-link {
                        height: 2.25em;
                    }
                }
            }
        }
    }

    &.verification-container {
        .icon {
            vertical-align: middle;
        }
    }
}
