.personalpage .block-content {
    padding: .25rem .625rem;

    fieldset {
        padding: 0 0 .125rem;
        border: 0;
    }
}

.auth-service-user {
    input[type="text"],
    input[type="email"] {
        width: 100%;
    }

    .birth-date {
        max-width: 8rem;
    }

    // Temporary override to disallow changes
    // Can be removed after SPR-3298
    [readonly] {
        background-color: #f6f6f6;
        cursor: not-allowed;
    }
}

.newsletter {
    h4 {
        margin-bottom: .125rem;
    }

    .checkbox-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .5rem;

        input[type="checkbox"] {
            margin-top: .25rem;
        }

        @media screen and (min-width: 600px) {
            align-items: center;

            input[type="checkbox"] {
                margin: 0;
            }
        }
    }

    .button.newsletter-button {
        // Prevent flickering button onLoad
        display: none;
    }
}
