@import "../../umbrella/scss/mixins/breakpoint";
@import "../../rainbow/scss/colors";

p {
    padding: 0;
}

a {
    text-decoration: underline;
}

.column-left {
    display: block;
    width: 74%;
    float: left;

    // TODO: Improve responsiveness
    @include breakpoint("medium") {
        max-width: 540px;
    }

    @include breakpoint("max-medium") {
        width: 98%;
    }

    .block {
        margin-bottom: $block-section-bottom-margin;
        clear: left;
    }
}

.column-right {
    display: block;
    width: 24%;
    min-width: 273px; // width of image
    max-width: 305px;
    margin-left: $unit * 4;
    float: left;

    @include breakpoint("max-medium") {
        display: none;
    }
}

// Buttons at the bottom of every tab
.save-or-return {
    display: flex;
    justify-content: space-between;

    a {
        flex: 0 1 35%;
    }

    button {
        flex: 0 1 30%;
    }

    @media only screen and (max-width: $mobile-max-viewport-width) {
        a {
            flex: 1 0 100%;
        }

        button {
            flex: 1 0 100%;
            margin-bottom: $unit;
        }
    }
}
