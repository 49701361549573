@import "../../rainbow/scss/colors";

.message-box {
    display: block;
    width: 100%;
    float: left;
    clear: left;

    p {
        margin: 0;
    }

    .message {
        margin-bottom: 15px;
        padding: 15px;
        border: 1px solid $alert-success-border;
        border-radius: 2px;
        background-color: $alert-success-background;
        color: $alert-success-text;
    }

    .error {
        border: 1px solid $alert-error-border;
        background-color: $alert-error-background;
        color: $alert-error-text;
    }

    .close {
        float: right;
        opacity: .5;
        color: $alert-success-text;
        font-size: 1.6em;
        font-weight: bold;
        text-decoration: none;
        text-shadow: 0 1px 0 #fff;
        cursor: pointer;
    }
}
